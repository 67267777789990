<template>
  <div class="container bgblue">
    <div class="header">

      <el-row>
        <el-form :model="form" class="demo-form-inline" label-width="80px">
          <el-col :span="6">
            <el-form-item label="物料名称:">
              <el-input
                v-model="form.productName"
                placeholder="请输入物料名称"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="物料类型:">
              <el-input
                v-model="form.specs"
                placeholder="请输入物料类型"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="状态:">
              <el-select
                v-model="form.status"
                @change="changeStatus"
                placeholder="请选择状态"
                clearable
              >
                <el-option label="已入库" value="0"></el-option>
                <el-option label="未入库" value="1"></el-option>
                <el-option label="已出库" value="2"></el-option>
                <el-option label="未出库" value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="摘要:">
              <el-input
                v-model="form.orderCode"
                placeholder="请输入订单号"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="操作时间:">
              <el-date-picker
                v-model="form.date"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="changeDate"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="20px">
              <el-button type="primary" icon="el-icon-search" @click="onSubmit"
                >查询</el-button
              >
              <el-button icon="el-icon-refresh" @click="onReset"
                >重置</el-button
              >
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
    </div>
    <div class="table">
      <el-table :data="tableData" style="width: 100%" border height="93%">
        <el-table-column align="center" prop="inOutFlag" label="操作">
          <template v-slot="{ row }">
            <el-button v-if="row.status == 0" class="btn-green" type="text">已入库</el-button>
            <el-button v-if="row.status == 1" class="btn-orange" type="text">未入库</el-button>
            <el-button v-if="row.status == 2" class="btn-green" type="text">已出库</el-button>
            <el-button v-if="row.status == 3" class="btn-orange" type="text">未出库</el-button>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="specs" label="物料类型">
        </el-table-column>
        <el-table-column align="center" prop="productName" label="物料名称">
        </el-table-column>
        <el-table-column align="center" prop="realNum" label="物料数量">
        </el-table-column>
        <el-table-column align="center" prop="unit" label="物料单位">
        </el-table-column>
        <el-table-column align="center" prop="createTime" label="操作时间">
        </el-table-column>
        <el-table-column align="center" prop="orderCode" label="摘要">
          <template v-slot="{ row }">
            <span>订单{{ row.orderCode }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="page">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="params.currPage"
          :page-size="params.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totol"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      detailList: [], //详情表格
      datailObj: {}, //详情对象
      showDialog: false,
      form: {
        orderCode: "",
        productName: "",
        specs: "",
        inOutFlag: "",
        beginTime: "",
        endTime: "",
      },
      params: {
        count: 0,
        currPage: 1,
        pageCount: 0,
        pageSize: 10,
      },
      totol: 0,
    };
  },
  methods: {
    onSubmit() {
      this.getData();
    },
    async getData() {
      let condition = {
        ...this.form,
      };
      const {
        data: { data },
      } = await this.$http.post("/storeRecordBind/list", {
        condition,
        ...this.params,
      });
      this.totol = data.count;
      this.tableData = data.data;
    },
    // 查看明细
    async handelDetail(row) {
      this.datailObj = row;
      this.showDialog = true;
      const {
        data: { data },
      } = await this.$http.post("/storeRecordBind/queryList", {
        inOutId: "1793453102127161345",
      });
      this.detailList = data;
    },
    // 更改当前数量
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getData();
    },
    // 更改当前页数
    handleCurrentChange(val) {
      this.params.currPage = val;
      this.getData();
    },
    onReset() {
      this.form = {};
      this.getData();
    },
    //状态
    changeStatus(val) {
      this.form.status = val;
    },
    //日期
    changeDate(val) {
      if (val) {
        this.form.beginTime = val[0];
        this.form.endTime = val[1];
      } else {
        this.form.beginTime = "";
        this.form.endTime = "";
      }
    },
  },
  created() {
    if (!this.$route.query.specs) {
      this.form.specs = "";
      this.getData();
    } else {
      this.form.specs = this.$route.query.specs;
      this.getData();
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 5px;
  box-sizing: border-box;

  ::v-deep .el-table td {
      padding: 0.5px 0;
      box-sizing: border-box;
    }
    ::v-deep .el-form-item__label{
    font-size: 13px;
   

  }
  ::v-deep .el-table{
    margin-top: 0px !important;
  }

  .header {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    padding-bottom: 0px !important;
    padding: 10px;
    box-sizing: border-box;
     box-shadow: 0px 0px 10px #ccc;
    ::v-deep .el-select {
      width: 100% !important;

    }

    ::v-deep .el-input__inner {
      width: 100% !important;
    }
  

  }
  .table {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;

    padding: 10px;
    box-sizing: border-box;
    margin-top: 10px;
    height: calc(100vh - 185px);
  }
  
  .page {
    width: 100%;
    text-align: end;
  }
  .dialog {
    ::v-deep .el-button {
      width: 100px !important;
      height: 35px !important;
    }
  }
}
::v-deep .el-dialog__body {
  padding: 0 0 30px 0 !important;
}

  ::v-deep
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td {
  background-color: #f59a23 !important;
}

</style>
